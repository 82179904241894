import * as React from "react"

export default function NextUp(props) {
    return (
        <div className="grid justify-self-auto bg-neutral-100 rounded-md relative content-center">
            <div className="text-center ">
                <h3 className="text-2xl font-bold m-2">Choosing Next:</h3>
                <div className="inline-block rounded shadow bg-white dark:bg-gray-900">
                    <p className="text-gray-900 dark:text-white px-2 first-letter:text-6xl first-letter:float-left first-letter:mt-0.5 font-black">{props.name}</p>
                </div>
            </div>
        </div>
    )
  }