import * as React from "react"
import RestaurantImage from "../components/restaurantImage"
import { StaticImage } from "gatsby-plugin-image"

function PlaceHolder() {
    return <StaticImage className="w-full rounded-md" src="https://picsum.photos/640/320" alt="Placeholder" />
}

function Location(name, url) {
    var output
    if(url) {
        output = <p className="float-right font-thin text-sm"><a href={url} target="_blank" rel="noreferrer" className="hover:text-underline">{name}</a></p>
    } else {
        output = <p className="float-right font-thin text-sm">{name}</p>
    }
    return output
}

function Notes(notes) {
    var output
    if(notes) {
        output = <p className="text-sm text-gray-500 clear-both"><em><strong>Notes:</strong> {notes}</em></p>
    } else {
        output = <p className="text-sm text-gray-500 clear-both">&nbsp;</p>
    }
    return output
}

function Count(total, index) {
    var output
    var value
    var count = (total - 1) - index

    if(count > 40) {
        value = count
    } else if(count < 40) {
        value = count + 1
    } else {
        value = ''
    }
    output = <p className="text-gray-300 dark:text-gray-300 text-right text-xl"><em>{value}</em></p>
    return output
}

export default function Restaurant(props) {
    var img = props.image ? <RestaurantImage fileName={props.image} /> : PlaceHolder()
    return (
        <div className="justify-self-auto bg-neutral-100 rounded-md relative">
            <div className="w-full">
                {img}
            </div>
           
            <div className="p-2">
                <h3 className="text-2xl font-bold float-left">
                    <a href={props.url} rel="noreferrer" target="_blank" className="hover:text-gray-600">{props.name}</a>
                </h3>
                {Location(props.location, props.map_url)}
                {Notes(props.notes)}
                <p className="absolute top-4 right-4 rounded shadow bg-white text-gray-900 dark:bg-gray-900 dark:text-white px-2 py-0.5 font-black text-lg">
                    {props.month}
                </p>
                <div className="absolute top-4 left-4 rounded shadow bg-white dark:bg-gray-900">
                    <p className="text-gray-900 dark:text-white px-2 first-letter:text-6xl first-letter:float-left first-letter:mt-0.5 text-xl font-black">{props.members[props.organiser]}</p>  
                </div>
                <div className="absolute top-14 right-4">
                    {props.attended ? props.attended.map((attendee, i) => (
                        <div className="inline-block align-middle ml-1.5 rounded shadow bg-white dark:bg-gray-900">
                            <p className="text-gray-900 dark:text-white px-2 first-letter:text-base first-letter:float-left first-letter:mt-0.5 text-xs leading-relaxed">{props.members[attendee]}</p>
                        </div>
                    )) : ''}
                </div>
                <div className="absolute bottom-2 right-2">
                    {Count(props.total, props.index)}
                </div>
            </div>
        </div>
    )
  }