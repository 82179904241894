import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const RestaurantImage = ({ fileName, ...rest }) => {
  const data = useStaticQuery(graphql`
    query {
        images: allFile(filter: {sourceInstanceName: {eq: "images"}}) {
            edges {
                node {
                    id
                    name
                    base
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
        }
    }
  `);

    const match = useMemo(() => data.images.edges.find(({ node }) => fileName === node.base), [data, fileName]);
    if (!match) return null;
    const image = getImage(match.node);
    return <GatsbyImage className="w-full rounded-md" image={image} alt={match.node.name} /> 
};

export default RestaurantImage;