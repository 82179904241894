import * as React from "react"
import data from "../../data/data.json"
import { Helmet } from 'react-helmet'
import Restaurant from "../components/restaurant"
import NextUp from "../components/nextUp"
import Tally from "../components/tally"

// markup
export default function IndexPage() {
  const sortedData = data.restaurants.reverse()
  const memberPos = sortedData[0].organiser
  const nextChooser = data.members[((memberPos >= (data.members.length - 1)) ? 0 : memberPos + 1 )]
  return (
    <>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-51JNQYZ8LQ"></script>
        <script>{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-51JNQYZ8LQ');
        `}</script>
        <title>Porcus Pals</title>
        <body className="bg-white text-gray-900 dark:bg-gray-900" />
      </Helmet>

      <div className="container mx-auto">
        <h1 className="text-5xl text-center m-4 font-black dark:text-white">Porcus Pals</h1>
        <h2 className="text-2xl text-center m-4 dark:text-white">The story of <Tally data={sortedData} /> meals so far...</h2>

        <div className="grid justify-items-stretch grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <NextUp key="next" name={nextChooser} />

          {sortedData.map((restaurant, i) => (
            <Restaurant key={`${"r" + i}`} total={sortedData.length} index={i} members={data.members} {...restaurant} />
          ))}
        </div>
    </div>
  </>
  )
}


